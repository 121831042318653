/* Back to top  */

#button {
  display: inline-block;
  background-color: #ff9800;
  /* background-color: #156aeb; */
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 25px;
  right: 110px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  text-decoration: none;
}

#button::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}

#button:hover {
  cursor: pointer;
  background-color: #333;
}

#button:active {
  background-color: #555;
}

#button.show {
  opacity: 1;
  visibility: visible;
}

/* End Back to top  */

/* whatsapp button  */

.whatsapp-button {
  position: fixed;
  bottom: 23px;
  right: 30px;
  background-color: #25d366;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  cursor: pointer;
  transition: opacity 0.3s, visibility 0.3s;
  text-decoration: none;
}

.whatsapp-button i {
  color: white;
  font-size: 34px;
}

.whatsapp-button i:hover {
  color: #333;
}

/* end whatsapp button */
