.about-wrapper {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  background-color: white;
  background-position: center;
  overflow-x: hidden;
}

.about-section {
  margin: 60px 0 0 0;
}

.about-line::after {
    content: "";
    display: block;
    width: 15%;
    height: 2px;
    background: white;
    margin: 10px auto 0;
}

.about-bg {
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../../assets/about/1.jpg);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-bg h4 {
  font-size: 1.3rem;
  font-weight: 400;
  color: white;
  transition: color 0.5s ease;
}

.about-bg h2 {
  font-size: 3rem;
  text-transform: uppercase;
  padding: 0.4rem 0;
  letter-spacing: 4px;
  color: white;
  transition: color 0.5s ease;
  margin: 0;
  padding: 0;
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 200px;
  margin: 0;
  padding: 0;
}

.line div {
  margin: 0 0.2rem;
}

.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: #f7327a;
  border-radius: 5px;
}

.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #f7327a;
  border-radius: 50%;
}

/* About text */

.about-text {
  max-width: 600px;
  margin: 20px auto;
}

.container {
  padding: 20px;
}

.about-text h3 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  text-align: center;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
}

.about-text p {
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  transition: color 0.3s ease;
  text-align: center;
}

.about-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.about-feature h4 {
  font-size: 1.2rem;
  /* color: #037ef3; */
  color: black;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.char {
  transform: translateY(115px);
  transition: transform 0.5s;
}

.about-info {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  color: #037ef3;
}

h4 {
  color: #212529;
}

/* New card */

.new-card {
  background-image: url("../../assets/about/so-white.png");
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 50px auto;
  overflow: hidden;
  padding: 20px;
}

.new-container h2 {
  text-align: center;
  margin-top: 5%;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
}

.new-container p {
  margin-top: 3%;
  color: #666;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  transition: color 0.3s ease;
  text-align: justify;
}

/* media query */

@media (max-width: 576px) {
  .about-bg h2 {
    font-size: 2rem;
  }

  .about-bg h3 {
    font-size: 1rem;
  }

  .about-text h3 {
    font-size: 1.2rem;
  }

  .about-info {
    font-size: 0.9rem;
  }

  .new-container h2 {
    font-size: 1.5rem;
  }

  .new-container p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .about-bg h2 {
    font-size: 2.5rem;
  }

  .about-bg h3 {
    font-size: 1.1rem;
  }

  .about-details {
    flex-direction: column;
    align-items: center;
  }

  .about-text h3 {
    font-size: 1.3rem;
  }

  .about-info {
    font-size: 1rem;
  }

  .new-container h2 {
    font-size: 1.3rem;
  }

  .main-container {
    margin-top: 60px;
  }
}

@media (max-width: 992px) {
  .about-details {
    flex-direction: column;
  }
}







.hero-about {
  background: url(../../assets/about/blob-top-left.png), url(../../assets/about/blob-top-right.png), url(../../assets/about/blob-bottom-left.png), url(../../assets/about/blob-bottom-right.png), url(../../assets/about/blob-center.png), url(../../assets/about/bg-bottom.png);
  background-position: left 0px top 0px, right 0px top 0px, left 0px bottom 0px, right 0px bottom 0px, center center, center bottom;
  background-repeat: no-repeat;
}

@media (max-width: 991.98px) {
  .hero-header {
    padding: 6rem 0 9rem 0;
  }
}

.text-white {
  color: white;
}

.animated.slideInDown {
  animation: slideInDown 1s ease-in-out;
}

@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.line div {
  margin: 0 0.2rem;
}

.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: #f7327a;
  border-radius: 5px;
}

.line {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #f7327a;
  border-radius: 50%;
}

.breadcrumb {
  background: transparent;
  padding: 0;
  margin-top: 1rem;
}

.breadcrumb-item {
  color: white;
}

.breadcrumb-item.active {
  font-weight: bold;
}

@media (max-width: 768px) {
  .hero-about h1 {
    text-align: center;
    margin: 0 auto;
  }
}
