.client-wrapper {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  background-color: white;
  overflow-x: hidden;
}

.clients-section {
  margin: 60px 0 0 0;
}

.clients-line::after {
  content: "";
  display: block;
  width: 12%;
  height: 2px;
  background: white;
  margin: 10px auto 0;
}

.clients-bg {
  height: 40vh;
  background: url(../../assets/clients/1.jpg);
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clients-bg h3 {
  font-size: 1.3rem;
  font-weight: 400;
  color: white;
}

.clients-bg h2 {
  font-size: 3rem;
  text-transform: uppercase;
  padding: 0.4rem 0;
  letter-spacing: 4px;
  margin: 0;
  padding: 0;
}

.line div {
  margin: 0 0.2rem;
}

.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: #f7327a;
  border-radius: 5px;
}

.line {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #f7327a;
  border-radius: 50%;
}

/* Card Styles */

.clients-card {
  background-color: #fff;
  background-image: url("../../assets/clients/card\ body.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 10%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  transition: 0.3s;
}

.clients-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.left,
.right {
  flex: 1;
  padding: 20px;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.left h1 {
  margin-bottom: 10px;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.left p {
  color: #666;
  line-height: 1.6;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.button-container .button-image {
  width: 170px;
  height: 50px;
  margin: 20px 0 0 0;
}

.button-container .button-image:active {
  transform: scale(0.95);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right img {
  max-width: 200px;
  border-radius: 10px;
}

/* Media query */
@media (max-width: 768px) {
  .clients-bg h2 {
    font-size: 2rem;
    letter-spacing: 2px;
  }

  .clients-bg h3 {
    font-size: 1rem;
  }

  .clients-card {
    flex-direction: column;
    max-width: 90%;
    margin: 20px auto;
  }

  .left,
  .right {
    padding: 10px;
    flex: none;
    text-align: center;
  }

  .right img {
    max-width: 150px;
  }

  .button-container {
    justify-content: center;
  }

  .button-container .button-image {
    width: 140px;
    height: 45px;
  }
}
