@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.bai-wrapper {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/triangle-mosaic.png");
  overflow-x: hidden;
}

p {
  text-align: justify;
}

.blog-container {
  padding: 20px;
  max-width: 1200px;
  margin: 200px 0 0 60px;
}

.header {
  margin-bottom: 20px;
}

.small-text {
  display: flex;
  align-items: center;
  gap: 18px;
  color: #555;
  margin: 0 0 20px 0;
}

.category,
.separator,
.date {
  margin: 0;
}

.category {
  font-size: 15px;
  font-weight: bold;
  color: blue;
}

.separator {
  color: blue;
}

.date {
  font-size: 18px;
  color: #777;
}

.header h1 {
  font-size: 1.5em;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 10px 0 10px 0;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.blog-image {
  width: 120px;
  height: auto;
  border-radius: 8px;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details .written-by {
  font-size: 14px;
  margin: 0;
  color: #777;
}

.details a {
  font-size: 18px;
  font-weight: bold;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 0;
  text-decoration: none;
}

.details a:hover {
  color: blue;
}

.blog-container .blog-content {
  margin: 80px 0 0 0;
}

.blog-content img {
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: 0 0 20px 0;
}

.blog-content h4 {
  font-size: 1.5em;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 10px 0 10px 0;
}

.blog-content .span-heading {
  color: #0b48ed;
  font-size: 16px;
  font-weight: bold;
}

.blog-container.blog-content p {
  color: #212529;
  font-size: 16px;
  font-weight: bold;
}

.blog-container .blog-content h1 {
  margin: 20px 0 20px;
}

/* card */

.bai-card-title {
  text-align: center;
  color: blue;
  font-size: 1.5em;
}

.bai-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.bai-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 340px;
  margin: 10px;
}

.bai-card img {
  width: 100%;
  height: 40%;
  padding: 10px 10px 10px 10px;
}

.bai-card-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.small-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #555;
}

.date {
  font-size: 14px;
  color: blue;
}

h3 {
  padding: 10px;
  font-size: 18px;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 0;
}

.learn-more {
  display: block;
  padding: 10px;
  margin: 0 0 40px 0;
  color: #037ef3;
  text-decoration: none;
  font-weight: bold;
  text-align: left;
}

.learn-more:hover {
  color: #4579aa;
}

.learn-more i {
  margin-left: 5px;
}

.bai-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .bai-card-container {
    flex-direction: column;
    align-items: center;
  }

  .bai-card {
    margin: 0 auto;
  }
}

@media (min-width: 769px) {
  .bai-card-container {
    flex-direction: row;
  }
}

/* End Card */

/* Contact Section */

.contact-section {
  background-color: #e6dbdb;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-form {
  background-image: url("../../../assets/contact/bg.jpg");
  background-size: cover;
  background-position: center;
  max-width: 600px;
  margin-right: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  flex: 1;
}

.contact-form h2 {
  margin-bottom: 20px;
  font-size: 1.8em;
  text-align: center;
}

.contact-form h5 {
  font-size: 1.5em;
  text-align: center;
  margin: 20px 0;
  color: #0b48ed;
}

.contact-form .line div {
  margin: 0 0.2rem;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #8fcce0;
  outline: none;
}

.contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #193ac0;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 30px;
  width: 100%;
  transition: transform 0.2s ease;
}

.contact-form button:hover {
  background-color: #0b48ed;
}

.contact-form button:active {
  transform: scale(0.98);
}

.contact-image {
  flex: 1;
  max-width: 100%;
  text-align: center;
  margin-right: 20px;
}

.contact-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  animation: moveUpDown 2s infinite;
}

.our-services {
  color: blue;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Media Query */

@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
    padding: 10px;
  }

  .contact-form {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .contact-image {
    max-width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .contact-form h2 {
    font-size: 1.5em;
  }

  .contact-form h3 {
    font-size: 1.2em;
  }
}

/* End Contact Section */

/* Media Query */

@media (max-width: 768px) {
  .blog-container {
    margin: 100px 20px;
    padding: 10px;
  }

  .header {
    margin-bottom: 15px;
  }

  .small-text {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .category,
  .separator,
  .date {
    font-size: 16px;
  }

  h1 {
    font-size: 1.5em;
    margin: 0 0 20px 0;
  }

  .content {
    flex-direction: column;
    align-items: flex-start;
  }

  .blog-image {
    width: 100px;
    max-width: none;
    height: auto;
  }

  .details {
    align-items: flex-start;
  }

  .details .written-by {
    font-size: 12px;
  }

  .details a {
    font-size: 16px;
  }

  .blog-content img {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .small-text {
    font-size: 14px;
    gap: 6px;
  }

  .category,
  .separator,
  .date {
    font-size: 14px;
  }

  h1 {
    font-size: 1.2em;
  }

  .details .written-by {
    font-size: 10px;
  }

  .details a {
    font-size: 14px;
  }
}
