@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

nav {
  position: fixed;
  z-index: 10000;
  width: 100%;
  background: white;
  box-shadow: 0.7px 8.9px 24.5px 0 rgba(8, 8, 24, 0.1);
}

nav .wrapper {
  position: relative;
  max-width: 1300px;
  padding: 0px 30px;
  height: 80px;
  line-height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo a {
  color: white;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.wrapper .logo a img {
  width: 130px;
}

.wrapper .nav-links {
  display: inline-flex;
  padding-top: 17px;
}

.nav-links li {
  list-style: none;
}

.nav-links li a {
  text-decoration: none;
  color: #242526;
  font-size: 17px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  background: #e6e0e0;
}

.nav-links .mobile-item {
  display: none;
}

.nav-links .drop-menu {
  position: absolute;
  background: white;
  width: 250px;
  line-height: 45px;
  top: 90px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  text-align: left;
  justify-content: center;
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 80px;
  opacity: 1;
  visibility: visible;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  border-radius: 0px;
  color: #242526;
  font-size: 16px;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 100px;
  opacity: 0;
  visibility: hidden;
}

.mega-box .content {
  background: white;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.mega-box .content .row {
  width: calc(50% - 20px);
  line-height: 45px;
}

.content .row img {
  width: 60%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}

.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.row .mega-links li {
  padding: 0 20px;
}

.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #242526;
  font-size: 16px;
  display: block;
}

.row .mega-links li a:hover {
  color: #242526;
}

.wrapper .btn {
  color: #242526;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

.cta-button {
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.cta-btn {
  background-color: transparent;
  border: 1px solid blue;
  color: #242526;
  font-size: 15px;
  font-weight: 500;
  padding: 8px 16px;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px;
  transition: all 0.3s ease;
  line-height: normal;
  margin: 0;
  display: inline-block;
  position: relative;
}

.cta-btn:hover {
  background-color: blue;
  border-color: blue;
}

.cta-btn a {
  color: black;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  transition: color 0.3s ease;
  padding: 0;
  margin: 0;
  line-height: normal;
}

.cta-btn:hover a {
  color: white;
}

.cta-btn:active {
  background-color: darkblue;
  border-color: darkblue;
  transform: scale(0.96);
}

.cta-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120%;
  height: 120%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.cta-btn:hover::before {
  opacity: 1;
}

.cta-btn a {
  position: relative;
  z-index: 1;
}

.nav-links li:last-child {
  margin-right: 20px;
}

.cta-button {
  margin-left: 20px;
}

/* Media query */

@media screen and (max-width: 950px) {
  .wrapper {
    justify-content: center;
    position: relative;
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .wrapper .btn.menu-btn {
    position: absolute;
    font-size: 25px;
    right: 15px;
    top: 15px;
  }

  .wrapper .btn {
    display: block;
  }

  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #f6f5f8;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #c0c5ca;
  }

  ::-webkit-scrollbar-thumb {
    background: #c0c5ca;
  }

  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }

  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }

  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }

  .nav-links li {
    margin: 15px 10px;
  }

  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }

  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
    left: 0;
    transform: none;
    top: 60px;
    line-height: 40px;
    box-shadow: none;
  }

  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box {
    max-height: 100%;
  }

  .nav-links .desktop-item {
    display: none;
  }

  .nav-links .mobile-item {
    display: block;
    color: #242526;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .nav-links .mobile-item:hover {
    background: #c0c5ca;
  }

  .drop-menu li {
    margin: 0;
  }

  .drop-menu li a {
    border-radius: 5px;
    font-size: 15px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }

  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .mega-box .content .row a {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }

  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }

  .row .mega-links li {
    margin: 0;
  }

  .content .row header {
    font-size: 19px;
  }
}

nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}

.body-text div {
  font-size: 45px;
  font-weight: 600;
}
