@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.bsoftware-wrapper {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/triangle-mosaic.png");
  overflow-x: hidden;
}

p {
  text-align: justify;
}

.blog-container {
  padding: 20px;
  max-width: 1200px;
  margin: 200px 0 0 60px;
}

.header {
  margin-bottom: 20px;
}

.small-text {
  display: flex;
  align-items: center;
  gap: 18px;
  color: #555;
  margin: 0 0 20px 0;
}

.category,
.separator,
.date {
  margin: 0;
}

.category {
  font-size: 15px;
  font-weight: bold;
  color: blue;
}

.separator {
  color: blue;
}

.date {
  font-size: 18px;
  color: #777;
}

.header h1 {
  font-size: 1.5em;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 10px 0 10px 0;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.blog-image {
  width: 120px;
  height: auto;
  border-radius: 8px;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details .written-by {
  font-size: 14px;
  margin: 0;
  color: #777;
}

.details a {
  font-size: 18px;
  font-weight: bold;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 0;
  text-decoration: none;
}

.details a:hover {
  color: blue;
}

.blog-container .blog-content {
  margin: 80px 0 0 0;
}

.blog-content img {
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: 0 0 20px 0;
}

.blog-content h4 {
  font-size: 1.5em;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 10px 0 10px 0;
}

.blog-content .span-heading {
  color: #0b48ed;
  font-size: 16px;
  font-weight: bold;
}

.blog-container.blog-content p {
  color: #212529;
  font-size: 16px;
  font-weight: bold;
}

.blog-container .blog-content h1 {
  margin: 20px 0 20px;
}

/* card */

.bsoftware-card-title {
  text-align: center;
  color: blue;
  font-size: 1.5em;
}

.bsoftware-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.bsoftware-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 340px;
  margin: 10px;
}

.bsoftware-card img {
  width: 100%;
  height: auto;
  padding: 10px 10px 10px 10px;
}

.bsoftware-card-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.small-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #555;
}

.date {
  font-size: 14px;
  color: blue;
}

h3 {
  padding: 10px;
  font-size: 18px;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 0;
}

.learn-more {
  display: block;
  padding: 10px;
  margin: 0 0 40px 0;
  color: #037ef3;
  text-decoration: none;
  font-weight: bold;
  text-align: left;
}

.learn-more:hover {
  color: #4579aa;
}

.learn-more i {
  margin-left: 5px;
}

.bsoftware-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .bsoftware-card-container {
    flex-direction: column;
    align-items: center;
  }

  .bsoftware-card {
    margin: 0 auto;
  }
}

@media (min-width: 769px) {
  .bsoftware-card-container {
    flex-direction: row;
  }
}

/* End Card */

/* Media Query */

@media (max-width: 768px) {
  .blog-container {
    margin: 100px 20px;
    padding: 10px;
  }

  .header {
    margin-bottom: 15px;
  }

  .small-text {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .category,
  .separator,
  .date {
    font-size: 16px;
  }

  h1 {
    font-size: 1.5em;
    margin: 0 0 20px 0;
  }

  .content {
    flex-direction: column;
    align-items: flex-start;
  }

  .blog-image {
    width: 100px;
    max-width: none;
    height: auto;
  }

  .details {
    align-items: flex-start;
  }

  .details .written-by {
    font-size: 12px;
  }

  .details a {
    font-size: 16px;
  }

  .blog-content img {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .small-text {
    font-size: 14px;
    gap: 6px;
  }

  .category,
  .separator,
  .date {
    font-size: 14px;
  }

  h1 {
    font-size: 1.2em;
  }

  .details .written-by {
    font-size: 10px;
  }

  .details a {
    font-size: 14px;
  }
}
