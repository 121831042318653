:root {
    --primary: #6222CC;
    --secondary: #FBA504;
    --light: #F6F4F9;
    --dark: #04000B;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.custom-footer {
    background:
        url(../../assets//footer//map.png);
    background-position:
        center top,
        center center;
    background-repeat: no-repeat;
    background-color: #6222CC !important;
}

.custom-footer .from-top {
  margin: 40px 0 0 0;
}

.custom-footer .btn.btn-social {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid var(--light);
    border-radius: 40px;
    transition: .3s;
}

.custom-footer .btn.btn-social:hover {
  color: var(--secondary);
}

.custom-footer .btn.btn-link {
    display: block;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
    color: var(--light);
    font-weight: normal;
    transition: .3s;
    text-decoration: none;
}

.custom-footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.custom-footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
    color: var(--secondary);
}

.custom-footer .copyright {
    padding: 25px 0;
    font-size: 14px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.custom-footer .copyright a {
    color: var(--light);
}

.custom-footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid var(--light);

}

.custom-footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

.custom-footer .copyright a:hover,
.custom-footer .footer-menu a:hover {
    color: var(--secondary);
}

.custom-footer .info {
  color: var(--light);
  font-size: 14px;
}

.custom-footer .footer-image {
    width: 150px;
    height: 100px;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; 
  white-space: nowrap;
}

/* Media query */

@media (min-width: 768px) {
  .copyright .col-md-6 {
    justify-content: center; 
  }
}
