* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-wrapper {
  background-color: white;
}

/* Main */

.main {
  position: relative;
  overflow: hidden;
}

.page1 {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.page1 video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page1 nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  position: absolute;
  height: 10vh;
  width: 100vw;
}

.bottom-page1 {
  position: absolute;
  bottom: 5%;
  height: 35vh;
  width: 50vw;
  left: 5%;
}

.main .page1 .bottom-page1 h2 {
  font-family: a;
  font-size: 5vw;
  font-weight: 100;
  line-height: 1;
  color: #fff;
}

.bottom-page1-inner {
  position: absolute;
  bottom: 0;
  height: 35%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: a;
}

.bottom-page1-inner button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: #fff;
  color: #0b48ed;
  font-size: 16px;
  font-family: b;
  white-space: nowrap;
  margin-left: 40px;
}

.bottom-page1-inner h4 {
  font-size: 1.3vw;
  font-weight: 100;
  color: #fff;
}

/* Media Query */

@media (max-width: 950px) {
  .page1 {
    height: auto;
    width: 100%;
    margin-top: 66px;
  }

  .page1 video {
    height: 300px;
  }

  .bottom-page1 {
    height: auto;
    width: 100%;
    left: 15px;
    margin-bottom: 60px;
  }

  .bottom-page1 h2 {
    font-size: 6vw;
    margin-bottom: 40px;
  }

  .bottom-page1-inner {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .bottom-page1-inner button {
    margin: 10px 0;
    font-size: 14px;
  }

  .bottom-page1-inner h4 {
    font-size: 3.5vw;
    margin: 0;
    width: 100%;
    max-width: 350px;
    line-height: 1.5;
  }
}

/* End Main */

/* Services */

.home-services {
  text-align: center;
  margin-top: 15px;
}

.home-services .service-heading {
  font-size: 1.5em;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  font-weight: 600;
}

.home-services h2 {
  color: #037ef3;
  font-size: 1.5em;
  font-weight: bold;
}

.home-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 200px;
}

.home-line div {
  margin: 0 0.2rem;
}

.home-line div:nth-child(1),
.home-line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: #f7327a;
  border-radius: 5px;
}

.home-line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #f7327a;
  border-radius: 50%;
}

.home-services-container {
  text-align: center;
  padding: 40px 40px;
  margin-top: 30px;
}

.home-services-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.home-services-card {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  margin: 20px 0;
  padding: 20px;
  text-align: center;
  overflow: hidden;
  background: #fff;
  background-image: url("../../assets/home/images/services/card-body.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 18%;
  transition: color 0.3s, transform 0.3s;
  transform: translateY(-60px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.home-services-card::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: #166ce7;
  transition: transform 1s ease;
  transform: translate(-50%, -50%) scale(0);
  z-index: 0;
}

.home-services-card:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.home-services-card:hover h4,
.home-services-card:hover p {
  color: white;
}

.home-services-card h4,
.home-services-card p,
.home-services-card img {
  position: relative;
  z-index: 1;
  transition: color 1s ease;
}

.home-services-card img {
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 0 0 20px 0;
}

.home-services-card h4 {
  margin: 20px 0 20px 0;
}

.home-services-card p {
  text-align: center;
}

/* end services  */

/* industries */

.industries {
  background-image: url(../../assets/home/images/industries-back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.industries h2 {
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
}

.industries-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.industry {
  width: calc(25% - 20px);
  padding: 20px;
  text-align: center;
}

.industry img {
  width: 30px;
  height: 30px;
}

.industry h4 {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

/* end industries */

/* technology */

.technologies {
  padding: 40px 20px;
  text-align: center;
}

.technologies h2 {
  text-align: center;
  color: #037ef3;
  font-size: 1.5em;
  font-weight: bold;
}

.technologies-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.technologies-container div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.technologies-container img {
  width: 85px;
  height: 85px;
  object-fit: contain;
  display: block;
  margin: 10px;
  padding: 5px;
  transition: transform 0.3s;
  background-color: #f5f5f5;
}

.technologies-container img:hover {
  transform: scale(1.1);
}

/* end technology */

/*  Clients  */

.clients {
  background-color: #f3e8e8;
  padding: 10px 20px;
  text-align: center;
  background: #7a60ff;
  background: linear-gradient(to left, #193ac0, #cd9ffa);
  position: relative;
}

.clients::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/home/images/clients/pattern.png);
}

.clients h2 {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 20px;
}

.clients-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.clients-container picture {
  padding: 10px;
  background-color: #fff;
  background-image: url("../../assets/home/images/testimonials/card-body.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 18%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: 0.3s;
}

.clients-container picture:hover {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.clients-container img {
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.clients-container h4 {
  text-align: center;
  color: #193ac0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  white-space: nowrap;
}

/* end Clients */

/* carousel */
/* 
.owl-slider {
    padding: 20px 80px 20px 80px;
    background-image: url('../../assets/home/images/testimonials/testimonials\ bg.jpg');
    background-size: cover;
    background-position: center;
    overflow-x: hidden;

}

.owl-slider h2 {
    text-align: center;
    color: #037ef3;
}

.testimonial-line {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 200px;
    margin-bottom: 50px;
}

.testimonial-line div {
    margin: 0 0.2rem;
}

.testimonial-line div:nth-child(1),
.testimonial-line div:nth-child(3) {
    height: 3px;
    width: 70px;
    background: #f7327a;
    border-radius: 5px;
}

.testimonial-line div:nth-child(2) {
    width: 10px;
    height: 10px;
    background: #f7327a;
    border-radius: 50%;
}

.testimonial-card {
    background-color: #fff;
    background-image: url('../../assets/home/images/testimonials/card-body.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 18%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stars {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
}

.stars i {
    color: #2d71ce;
    margin: 0 2px;
    font-size: 16px;
}

.testimonial-content {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}

.testimonial-content img {
    width: 60px;
    height: 60px;
    display: block;
    margin-left: 20px;
}

.testimonial-text {
    max-width: 300px;
    margin: 10px 0 0 20px;
}

.name {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #0a0c1e;
}

.role {
    margin: 0;
    color: #555;
    font-size: 14px;
    font-weight: 500;
}

.message {
    display: flex;
    align-items: flex-start;
    margin: 20px 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: rgba(6, 41, 83, 0.7);
}

.owl-nav button {
    position: absolute;
    top: 50%;
    background-color: #000;
    color: #fff;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.owl-nav button.owl-prev {
    position: absolute;
    left: 0;
}

.owl-nav button.owl-next {
    position: absolute;
    right: 0;
}

.owl-dots {
    text-align: center;
    padding-top: 15px;
}

.owl-dots button.owl-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background: #ccc;
    margin: 0 3px;
}

.owl-dots button.owl-dot.active {
    background-color: white;
}

.owl-dots button.owl-dot:focus {
    outline: none;
}

.owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.38) !important;
}

.owl-nav button:hover {
    background: rgba(255, 255, 255, 0.6) !important;
}

span {
    font-size: 70px;
    position: relative;
    top: -5px;
}

.owl-nav button:focus {
    outline: none;
} */

/* Media Queries */

@media (max-width: 1024px) {
  .owl-slider {
    padding: 20px 40px;
  }

  .testimonial-line {
    width: 150px;
    margin-bottom: 30px;
  }

  .testimonial-card {
    padding: 15px;
  }

  .testimonial-content img {
    width: 50px;
    height: 50px;
    margin-left: 15px;
  }

  .testimonial-text {
    max-width: 250px;
    margin: 10px 0 0 15px;
  }
}

@media (max-width: 768px) {
  .owl-slider {
    padding: 20px 20px;
  }

  .testimonial-line {
    width: 120px;
    margin-bottom: 20px;
  }

  .testimonial-card {
    padding: 10px;
  }

  .testimonial-content img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  .testimonial-text {
    max-width: 200px;
    margin: 10px 0 0 10px;
  }

  .owl-nav button {
    font-size: 20px;
  }

  .span {
    font-size: 50px;
  }
}

@media (max-width: 480px) {
  .owl-slider {
    padding: 10px;
  }

  .testimonial-line {
    width: 100px;
    margin-bottom: 15px;
  }

  .testimonial-card {
    padding: 10px;
  }

  .testimonial-content img {
    width: 35px;
    height: 35px;
    margin-left: 8px;
  }

  .testimonial-text {
    max-width: 180px;
    margin: 5px 0 0 8px;
  }

  .owl-nav button {
    width: 40px;
    height: 40px;
  }

  .span {
    font-size: 40px;
  }
}

/* end carousel */

/* milestones */

.milestones {
  background-color: #fff;
  padding: 40px 20px;
  text-align: center;
  width: 100%;
}

.milestones h2 {
  color: #037ef3;
  font-size: 1.5em;
  font-weight: bold;
}

.milestones-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin: 40px auto;
  max-width: 1200px;
  padding: 0 20px;
}

.milestones-container div div {
  flex: 1;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
}

.milestones-container div div {
  display: block;
}

.milestones-container div div img {
  width: 40px;
  height: 40px;
}

.milestones-container div div div:nth-child(2) {
  font-weight: 500;
  font-size: 1.1rem;
}

.milestones-container .text {
  padding-top: 0.4rem;
  font-weight: 600;
}

/* end milestones */

/* portfolio */

.portfolio {
  text-align: center;
  padding: 40px 20px;
  background-color: #f5f5f5;
  background: #7a60ff;
  background: linear-gradient(to left, blue, #cd9ffa);
  position: relative;
}

.portfolio::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/home/images/portfolio/pattern.png);
}

.portfolio h2 {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
}

.portfolio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.portfolio-container a {
  display: block;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.portfolio-container img {
  width: 400px;
  height: auto;
  display: block;
  transition: transform 0.3s;
}

.portfolio-container a:hover img {
  transform: scale(1.05);
}

.portfolio-container a:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* end portfolio */

/* Contact Section */

.home-contact-section {
  background-color: #e6dbdb;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-contact-form {
  background-image: url("../../assets/contact/bg.jpg");
  background-size: cover;
  background-position: center;
  flex: 1;
  max-width: 40%;
  margin-right: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.home-contact-form h2 {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin: 40px 0 40px 0;
  color: #0b48ed;
}

.home-contact-form .line div {
  margin: 0 0.2rem;
}

.home-contact-form form {
  display: flex;
  flex-direction: column;
}

.home-contact-form input,
.home-contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.home-contact-form input:focus,
.home-contact-form textarea:focus {
  box-shadow: 0 0 6px -3px rgba(48, 48, 48, 1);
  outline: none;
}

.home-contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #193ac0;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 30px;
  transition: transform 0.2s ease;
  outline: none;
}

.home-contact-form button:hover {
  background-color: #0b48ed;
}

.home-contact-form button:active {
  outline: none;
  transform: translateY(2px);
}

.home-contact-image {
  flex: 1;
  max-width: 50%;
  text-align: center;
}

.home-contact-image h3 {
  font-size: 1.5em;
  color: blue;
  position: relative;
  text-align: center;
}

.home-contact-image .home-contact-form-image {
  max-width: 80%;
  height: auto;
  border-radius: 8px;
  animation: moveUpDown 2s infinite;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Contact Section */

/* media query */

@media (max-width: 1200px) {
  .home-services-card {
    width: 45%;
  }

  .industry {
    width: calc(33.33% - 20px);
  }

  .technologies-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .clients-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .home-services-card {
    width: 100%;
  }

  .industry {
    width: calc(50% - 20px);
  }

  .technologies-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .clients-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .milestones-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .industry {
    width: 100%;
  }

  .technologies-container {
    grid-template-columns: 1fr;
    margin: 50px;
  }

  .clients-container {
    grid-template-columns: 1fr;
  }

  .clients-container h4 {
    font-size: 14px;
  }

  .milestones-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .milestones-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-contact-section {
    flex-direction: column;
  }

  .home-contact-form,
  .home-contact-image {
    max-width: 100%;
    margin: 10px 0;
  }

  .home-contact-image .home-contact-form-image {
    max-width: 100%;
  }
}
