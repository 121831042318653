@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

.blog-wrapper {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  background-color: white;
  overflow-x: hidden;
}

.blog-section {
  margin: 60px 0 0 0;
}

.blog-line::after {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background: white;
  margin: 10px auto 0;
}

.blog-bg {
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../../assets/blog/blog.jpg);
  background-position: 50% 90%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-bg h3 {
  font-size: 1.3rem;
  font-weight: 400;
}

.blog-bg h2 {
  font-size: 3rem;
  text-transform: uppercase;
  padding: 0.4rem 0;
  letter-spacing: 4px;
  margin: 0;
  padding: 0;
}

.line div {
  margin: 0 0.2rem;
}

.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: #f7327a;
  border-radius: 5px;
}

.line {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #f7327a;
  border-radius: 50%;
}

.text {
  font-weight: 300;
  opacity: 0.9;
}

.blog-bg .text {
  margin: 1.6rem 0;
}

/* blog text */

.blog-text {
  max-width: 600px;
  margin: 0 auto;
}

.container {
  padding: 20px;
}

.blog-text h3 {
  font-size: 1.5rem;
  margin-top: 40px;
  text-align: center;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
}

.blog-text p {
  margin: 20px 0 40px 0;
  text-align: center;
  font-weight: 600;
}

/* card */

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.blogs-card {
  background: #fff;
  background-image: url("../../assets/clients/card\ body.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 18%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
  margin: 10px;
}

.blogs-card img {
  width: 100%;
  height: 40%;
  padding: 10px 10px 10px 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.small-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #555;
}

.date {
  font-size: 14px;
  color: blue;
}

h3 {
  padding: 10px;
  font-size: 18px;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  margin: 0;
}

.learn-more {
  display: block;
  padding: 10px;
  margin: 0 0 40px 0;
  color: #037ef3;
  text-decoration: none;
  font-weight: bold;
  text-align: left;
}

.learn-more:hover {
  color: #4579aa;
}

.learn-more i {
  margin-left: 5px;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* media query */

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .blogs-card {
    margin: 0 auto;
  }
}

@media (min-width: 769px) {
  .card-container {
    flex-direction: row;
  }
}
