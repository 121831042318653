@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.contact-wrapper {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  background-color: white;
  background-position: center;
  overflow-x: hidden;
}

.contact {
  margin: 60px 0 0 0;
}

.contact-line::after {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background: white;
  margin: 10px auto 0;
}

.contact-bg {
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../../assets/contact/contact-bg.jpg);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-bg h3 {
  font-size: 1.3rem;
  font-weight: 400;
}

.contact-bg h2 {
  font-size: 3rem;
  text-transform: uppercase;
  padding: 0.4rem 0;
  letter-spacing: 4px;
  margin: 0;
  padding: 0;
}

.line div {
  margin: 0 0.2rem;
}

.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: #f7327a;
  border-radius: 5px;
}

.line {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #f7327a;
  border-radius: 50%;
}

.text {
  font-weight: 300;
  opacity: 0.9;
}

.contact-bg .text {
  margin: 1.6rem 0;
}

.contact-body {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 1rem;
}

.contact-info {
  margin: 2rem 0;
  text-align: center;
  padding: 2rem 0;
}

.contact-info span {
  display: block;
}

.contact-info div {
  margin: 0.8rem 0;
  padding: 1rem;
}

.contact-info span .fas {
  font-size: 2rem;
  padding-bottom: 0.9rem;
  color: blue;
}

.contact-info span .fas:hover {
  color: #f7327a;
}

.contact-info div span:nth-child(2) {
  font-weight: 500;
  font-size: 1.1rem;
}

.contact-info .text {
  padding-top: 0.4rem;
  color: blue;
  font-weight: bold;
}

/* form */

.contact_form {
  padding: 2rem 0;
  border-top: 1px solid #f7327a;
}

.contact_card {
  background-image: url("../../assets/contact/bg.jpg");
  background-size: cover;
  background-position: center;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact_form .contact_card form {
  padding-bottom: 1rem;
}

.contact_form .contact_card form h4 {
  /* color: #037ef3; */
  color: blue;
  font-weight: 600;
}

.form-control {
  width: 100%;
  border: 1.5px solid #c7c7c7;
  border-radius: 5px;
  padding: 0.7rem;
  margin: 0.6rem 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 6px -3px rgba(48, 48, 48, 1);
}

.name-field {
  grid-column: span 2;
}

.contact_form .contact_card form div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.6rem;
}

.send-btn {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  color: blue;
  font-weight: 600;
  border: 1px solid blue;
  border-radius: 5px;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
  transition: all 0.4s ease;
}

.send-btn:hover {
  opacity: 1;
  color: #fff;
  background: rgb(13, 13, 233);
}

.send-btn:active {
  transform: translateY(3px);
}

.contact_form .contact_card h2 {
  text-align: center;
  color: rgb(22, 112, 112);
}

.contact_right .contact_right_h3 {
  font-size: 1.5em;
  color: blue;
  position: relative;
  text-align: center;
  margin-top: 30px;
}

.contact_form > div .contact_contact_image {
  width: 85%;
  animation: moveLeftRight 2s infinite;
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
  }
}

.contact_form > div {
  margin: 0 auto;
  text-align: center;
}

.contact-footer {
  padding: 2rem 0;
  background: #000;
}

.contact-footer h3 {
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
}

.social-links a {
  text-decoration: none;
  width: 40px;
  height: 40px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
  transition: all 0.4s ease;
}

.social-links a:hover {
  color: #f7327a;
  border-color: #f7327a;
}

@media screen and (min-width: 768px) {
  .contact-bg .text {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .contact-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .contact-bg .text {
    width: 50%;
  }

  .contact_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .contact-info {
    grid-template-columns: repeat(4, 1fr);
  }
}
