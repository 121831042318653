.popup-container {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px; 
}

.popup-container .popup-image {
  width: 140px;
  height: 100px;
  margin-bottom: 10px;
}

.close-popup {
  color: #aaa;
  float: right;
  font-size: 28px;
  margin-right: 20px;
  font-weight: bold;
}

.close-popup:hover,
.close-popup:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.social-media-links a {
  color: #007bff;
  text-decoration: none;
  margin: 20px 10px;
}

.social-media-links a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Media query */

@media screen and (max-width: 768px) {
  .popup-container {
    z-index: 10000;
  }

  .popup-content {
    width: 90%;
    margin-top: 50%;
  }
}
