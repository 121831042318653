.product-wrapper {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: white;
  overflow-x: hidden;
}

.products-line::after {
  content: "";
  display: block;
  width: 25%;
  height: 2px;
  background: white;
  margin: 10px auto 0;
}

/* products-card Styles */

.products-card {
  background-color: #fff;
  background-image: url("../../assets/clients/card\ body.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 8%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  transition: 0.3s;
}

.products-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.products-left,
.right {
  flex: 1;
  padding: 20px;
}

.products-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.products-left h2 {
  margin-bottom: 10px;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
}

.products-left p {
  color: #666;
  line-height: 1.6;
}

.products-left .products-learn-more {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  text-decoration: none;
}

.hr-line {
  border: none;
  border-top: 1px solid blue;
  width: 80px;
  margin: 2px 0;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

a .website {
  width: 150px;
  height: 50px;
}

a .website:active {
  transform: scale(0.95);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right img {
  max-width: 250px;
  border-radius: 10px;
}

/* Media Query */

@media (max-width: 768px) {
  .design-container {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .image {
    width: 80%;
  }

  .products-card {
    flex-direction: column;
    max-width: 90%;
  }

  .right img {
    max-width: 100%;
  }

  h1 {
    font-size: 1.5em;
    margin: 20px;
  }

  .image {
    width: 80%;
    margin: 0 auto;
  }
}
