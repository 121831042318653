.icons {
  position: fixed;
  top: 30%;
  right: 0;
  width: 230px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  pointer-events: none;
}

.icons a {
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  font-size: 22px;
  font-family: "Oswald", sans-serif;
  margin: 2px;
  text-align: left;
  border-radius: 50px 0 0 50px;
  transform: translate(170px, 0px);
  transition: all 1s;
  pointer-events: auto;
}

.icons a:hover {
  transform: translate(10px, 0px);
}

.icons a i {
  margin-right: 20px;
  background-color: white;
  color: black;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  transition: all 1s;
}

.icons a:hover i {
  transform: rotate(360deg);
}

.icons a i.fa-facebook {
  color: #2c80d3;
}

.icons a i.fa-instagram {
  color: #fa0910;
}

.icons a i.fa-whatsapp {
  color: black;
}

.icons a i.fa-linkedin-in {
  color: #53c5ff;
}

.facebook {
  background-color: #2c80d3;
  color: white;
}

.instagram {
  background-color: #fa0910;
  color: white;
}

.linkedin {
  background-color: #53c5ff;
  color: white;
}

.whatsapp {
  background-color: black;
  color: white;
}
