.all-products-wrapper {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-image: url("../../../assets/services/bg.svg");
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
}

.design-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  height: 60%;
  margin-top: 100px;
}

h1 {
  margin: 0;
  font-size: 2em;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  flex: 1;
  margin: 50px 50px;
  text-align: center;
}

.images {
  width: 30%;
  height: auto;
  display: block;
  margin: 0 150px;
}

.centered {
  font-size: 2em;
  font-weight: normal;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  flex: 0 1 auto;
  margin: 40px 0 30px 0;
  text-align: center;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 180px;
  margin: 0 0 40px 0;
  justify-content: center;
}

a .website {
  width: 180px;
  height: 50px;
}

a .website:active {
  transform: scale(0.95);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.new-div {
  display: flex;
  justify-content: space-between;
  margin: 80px 20px;
}

.left-content {
  flex: 70%;
  margin-right: 40px;
}

.card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 30px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: 500;
}

.card h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #212529;
  filter: drop-shadow(1px 1px 1px gray);
  text-align: center;
}

.card .span-heading {
  color: #0b48ed;
  font-size: 16px;
}

.card p {
  font-size: 16px;
  color: #555;
  margin: 0;
  line-height: 1.6;
  text-align: justify;
}

.card p span {
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.right-content {
  flex: 30%;
  margin-left: 40px;
}

.form-card {
  background-color: #09348a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-right: 20px;
}

.form-card h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  position: relative;
}

.form-card h2::after {
  content: "";
  display: block;
  width: 30%;
  height: 2px;
  background: white;
  margin: 10px auto 0;
}

.form-card form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-card label {
  font-size: 16px;
  color: white;
}

.form-card input,
.form-card textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.form-card button {
  padding: 10px;
  background: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background 0.3s;
}

.form-card button:hover {
  background: #0056b3;
}

.form-card button:active {
  transform: scale(0.98);
  outline: none;
}

.card-features {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.um-feature-item {
  flex: 1 1 45%;
  text-align: center;
  font-size: 16px;
  color: #555;
  padding: 10px;
  box-sizing: border-box;
}

/* media query */

@media (max-width: 768px) {
  .design-container {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .images {
    width: 80%;
  }

  .new-div {
    flex-direction: column;
    margin: 40px 10px;
  }

  .left-content {
    margin-right: 0;
    margin-bottom: 20px;
    flex: 1;
  }

  .right-content {
    margin-left: 0;
    flex: 1;
  }

  .card {
    padding: 20px;
  }

  .card h2 {
    font-size: 20px;
  }

  .card p {
    font-size: 14px;
  }

  .form-card {
    margin: 0 auto;
    width: 100%;
  }

  .form-card h2 {
    font-size: 20px;
  }

  .form-card label {
    font-size: 14px;
  }

  .form-card input,
  .form-card textarea {
    font-size: 14px;
  }

  .form-card button {
    font-size: 14px;
  }

  .card-features {
    flex-direction: column;
  }

  .um-feature-item {
    flex: 1 1 100%;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5em;
  }

  .images {
    width: 100%;
  }

  .new-div {
    margin: 20px 5px;
  }

  .card {
    padding: 15px;
  }

  .card h2 {
    font-size: 18px;
  }

  .card p {
    font-size: 12px;
  }

  .form-card {
    padding: 15px;
    width: 90%;
    margin: 0 auto;
  }

  .form-card h2 {
    font-size: 18px;
  }

  .form-card label {
    font-size: 12px;
  }

  .form-card input,
  .form-card textarea {
    font-size: 12px;
  }

  .form-card button {
    font-size: 12px;
  }
}
